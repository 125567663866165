<template>
  <b-card class="container">
    <b-form @submit.prevent="editEstablishmentPrompt()">
      <div class="d-flex">
        <feather-icon icon="ShoppingBagIcon" size="19" />
        <h4 class="mb-0 ml-50">Información del establecimiento</h4>
      </div>
      <b-row>
        <b-col cols="12 my-1">
          <div class="d-flex">
            <base-cropper
              v-if="establishment"
              :model="establishment"
              @cropped-image="establishment.logo = $event"
            />
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-1 d-flex align-items-center">
        <b-col cols="12" md="6" lg="3" class="h-100">
          <b-form-group label="Nombre" label-for="name">
            <b-form-input
              v-model="establishment.name"
              class="form-control"
              placeholder=""
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" lg="3" class="h-100">
          <b-form-group
            label="Tipo de establecimiento"
            label-for="establishment_type"
          >
            <b-form-select
              v-model="establishment.establishment_type"
              :options="establishment_types"
            >
              <b-form-select-option
                v-if="userData.role_name === 'admin'"
                value="admin"
              >
                Admin
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" lg="3" class="h-100">
          <b-form-group
            label="¿Deseas registrarte como provedor de productos ó servicios para otros negocios?"
            label-for="is_supplier"
          >
            <b-button-group id='is_supplier' v-model="establishment.is_supplier" class="d-flex align-items-center">
              <b-button :variant="establishment.is_supplier==='supplier' ? 'success' : 'outline-success'" @click="handleIsSupplierButton('supplier')">Si</b-button>
              <b-button :variant="establishment.is_supplier==='not_supplier' ? 'danger' : 'outline-danger'" @click="handleIsSupplierButton('not_supplier')">No</b-button>
            </b-button-group>
          </b-form-group>
        </b-col>
        <!--
        <b-col
          cols="12"
          md="6"
          lg="3"
        >
          <b-form-group
            label="Límite por usuario en ventas a crédito"
            label-for="loan_limit"
          >
            <b-input-group prepend="$">
              <b-form-input
                v-model="establishment.loan_balance_limit"
                class="form-control"
                type="number"
                placeholder=""
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        -->
        <b-col
          cols="12"
          md="6"
          lg="3"
        >
          <b-form-group
            label="¿Manejarás una única cuenta bancaria para recibir o hacer pagos electrónicos como recargas de tiempo aire, recepción de pagos de servicios, pagos con tarjeta bancaria y transferencias?"
            label-for="balance-shared"
          >
            <b-button-group id='shared_bank_account' v-model="establishment.is_balance_shared" class="d-flex align-items-center">
              <b-button :variant="establishment.is_balance_shared ? 'success' : 'outline-success'" @click="handleSharedBalanceButton(true)">Si</b-button>
              <b-button :variant="establishment.is_balance_shared===false ? 'danger' : 'outline-danger'" @click="handleSharedBalanceButton(false)">No</b-button>
            </b-button-group>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Header: Personal Info -->
      <div class="d-flex mt-2">
        <feather-icon icon="UserIcon" size="19" />
        <h4 class="mb-0 ml-50">Información de contacto</h4>
      </div>

      <!-- Form: Personal Info Form -->
      <b-row class="mt-1">
        <!-- Field: Birth Date -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Nombre" label-for="contact-name">
            <b-form-input
              v-model="establishment.contact_name"
              class="form-control"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Mobile -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Número de Whatsapp para que tus clientes te contacten" label-for="mobile">
            <b-form-input id="mobile" v-model="whatsappNumber" />
          </b-form-group>
        </b-col>

        <!-- Field: Website -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Email" label-for="website">
            <b-form-input id="website" v-model="establishment.contact_email" />
          </b-form-group>
        </b-col>
      </b-row>

      <div class="d-flex mt-2">
        <feather-icon icon="ShoppingCartIcon" size="19" />
        <h4 class="mb-0 ml-50">Tienda en línea</h4>
      </div>

      <div class="landing__form mt-1" v-if="landingPage">
        <b-row>
          <b-col cols="12" md="8">
            <b-form-group label="Titulo" label-for="title">
              <b-form-input id="title" v-model="landingPage.title" />
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group label="Descripción" label-for="description">
          <b-form-textarea
            id="description"
            v-model="landingPage.description"
            rows="4"
            placeholder="Escriba algo..."
          />
        </b-form-group>

        <b-row>
          <b-col cols="12" md="8">
            <b-form-group label="URL amigable" label-for="slug">
              <b-row>
                <b-col cols="auto" class="d-flex align-items-center">
                  https://compitienda.com/
                </b-col>
                <b-col>
                  <b-form-input id="slug" v-model="establishment.slug" />
                </b-col>
                <b-col cols="auto">
                  <b-button variant="primary" @click="copyPathToClipboard(`${urlOrigin}/${establishment.slug}`)">
                    <feather-icon icon="CopyIcon" />
                    Copiar
                  </b-button>
                </b-col>
              </b-row>
            </b-form-group>
            <!-- URL currentPath + slug -->
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="8">
            <b-form-group label="Tema oscuro" label-for="dark_theme_enabled">
              <b-form-checkbox
                id="dark_theme_enabled"
                v-model="establishment.dark_theme_enabled"
                switch
              />
              <small>
                {{
                  establishment.dark_theme_enabled ? "Activado" : "Desactivado"
                }}
              </small>
            </b-form-group>
          </b-col>
        </b-row>
      </div>

      <p>Imágenes de la tienda</p>

      <div class="base-cropper__container mt-2 mb-1" v-if="landingPage">
        <div
          v-for="(image, index) in landingPage.images"
          :key="image.id || index"
          class="d-flex position-relative"
        >
          <feather-icon
            v-if="landingPage.images[index]"
            @click="clearImage(index)"
            icon="XCircleIcon"
            size="26"
            class="remove__icon"
          />
          <LandingBaseCropper
            :image="image.url"
            :index="index"
            @update:image="updateImage(index, $event)"
          />
        </div>

        <div
          v-if="showAddImage"
          @click="landingPage.images.push({ url: '' })"
          class="border add__image__container"
        >
          <feather-icon icon="PlusIcon" size="26" />
          <p>Agregar imagen</p>
        </div>
      </div>

      <!-- Header: Personal Info -->
      <div class="d-flex mt-2">
        <feather-icon icon="MapPinIcon" size="19" />
        <h4 class="mb-0 ml-50">Dirección</h4>
      </div>

      <!-- Form: Establishment address -->
      <div v-show="keepOldAddress">
        <p class="d-flex flex-column mt-1">
          <span class="text-muted">
            ¿La dirección de tu tienda es la siguiente? Si no, buscala abajo
          </span>
          {{ establishment.address_attributes.street }}
          {{ establishment.address_attributes.ext_number }}, CP
          {{ establishment.address_attributes.postal_code }},
          {{ establishment.address_attributes.city }},
          {{ establishment.address_attributes.state }},
          {{ establishment.address_attributes.country }}
        </p>
        <b-button class="mb-3 mt-1" @click="changeAddress()">
          Modificar dirección
        </b-button>
      </div>
      <div v-show="!keepOldAddress" class="mt-1">
        <address-form-model info-type @getAddress="setAddressForEdit($event)" />
      </div>
      <b-button v-show="!keepOldAddress" class="mb-3" @click="cancelAddress()">
        Cancelar
      </b-button>
      <div class="submit__buttons">
        <b-button
          variant="primary"
          class="mb-1"
          size="lg"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          type="submit"
        >
          Guardar
        </b-button>

        <b-button
          variant="outline-secondary"
          class="mb-1"
          size="lg"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          :to="{
            name: 'establishment-view',
            params: { id: $route.params.id },
          }"
        >
          Cancelar
        </b-button>
      </div>
    </b-form>
  </b-card>
</template>

<script>
import { mapActions } from "vuex"

// Components
import BaseCropper from "@/@core/components/BaseCropper.vue"
import AddressFormModel from "@/@core/components/AddressFormModel.vue"

import LandingBaseCropper from "@/@core/components/LandingBaseCropper.vue"

// Mixins
import messagesMixin from "@/@core/mixins/messagesMixin"

export default {
  components: {
    AddressFormModel,
    BaseCropper,
    LandingBaseCropper,
  },
  mixins: [messagesMixin],
  data() {
    return {
      urlOrigin: process.env.VUE_APP_BASE_URL,
      userData: JSON.parse(localStorage.getItem("userData")),
      establishment_types: [
        { value: "groceries", text: "Tiendita" },
        { value: "school", text: "Escuela" },
        { value: "restaurant", text: "Restaurante" },
        { value: "flower_store", text: "Librería" },
        { value: "hardware_store", text: "Ferretería" },
        { value: "tortilleria", text: "Tortillería" },
        { value: "coffee_shop", text: "Cafetería" },
        { value: "clothing_store", text: "Tienda de ropa" },
        { value: "water_purifier", text: "Purificadora de agua" },
        { value: "corporate_education", text: "Educación corporativa" },
        { value: "other", text: "Otra" },
      ],
      is_supplier_options: [
        { value: "supplier", text: "Si" },
        { value: "not_supplier", text: "No" },
      ],
      establishment: {
        address_attributes: {},
        logo: `${process.env.VUE_APP_API_URL}/default.jpg`,
      },
      landingPage: null,
      imagesToDestroy: [],
      prevLandingPage: {},
      prevEstablishment: {},
      keepOldAddress: true,
      addressId: "",
      whatsappNumber: "",
    }
  },
  mounted() {
    this.fetchEstablishment(this.$route.params.id).then((response) => {
      this.establishment = response
      this.whatsappNumber = `${this.establishment.cel_number}`.slice(2)
      this.prevEstablishment = { ...this.establishment }

      this.addressId = `${this.establishment.address_attributes.id}`

      this.landingPage = structuredClone(response.landing_page)
      this.prevLandingPage = structuredClone(this.landingPage)
    })
  },
  computed: {
    showAddImage() {
      if (!this.landingPage.images || this.landingPage.images.length === 0)
        return true
      const currentIndex = this.landingPage.images.length

      return (
        this.landingPage.images.length < 5 &&
        this.landingPage.images[currentIndex - 1]?.url
      )
    },
  },
  methods: {
    ...mapActions("establishments", [
      "fetchEstablishment",
      "editEstablishment",
      "editLandingPage",
    ]),
    ...mapActions("landingPage", ["editLandingPage"]),

    copyPathToClipboard(text) {
      navigator.clipboard.writeText(text)
      this.successToast(
        "Copiado",
        "La URL ha sido copiada al portapapeles",
        "CopyIcon"
      )
    },
    handleSharedBalanceButton(value) {
      this.establishment.is_balance_shared = value
    },
    handleIsSupplierButton(value) {
      this.establishment.is_supplier = value
      console.log(this.establishment.is_supplier)
    },
    setAddressForEdit(x) {
      this.establishment.address_attributes = x
    },
    changeAddress() {
      this.keepOldAddress = !this.keepOldAddress
    },
    clearImage(index) {
      const imageId = this.landingPage.images[index]?.id
      if (imageId) {
        this.imagesToDestroy.push(imageId)
      }

      this.landingPage.images.splice(index, 1)
    },
    updateImage(index, event) {
      const imageId = this.landingPage.images[index]?.id
      if (imageId) {
        this.imagesToDestroy.push(imageId)
      }
      this.landingPage.images[index].url = event
      this.landingPage.images[index].id = ""
    },

    cancelAddress() {
      this.establishment.address_attributes =
        this.prevEstablishment.address_attributes
      this.keepOldAddress = !this.keepOldAddress
    },
    async editEstablishmentPrompt() {
      this.establishment.cel_number = `${52}${
        this.whatsappNumber
      }`
      const landingChanged =
        JSON.stringify(this.landingPage) !==
        JSON.stringify(this.prevLandingPage)
      const establishmentChanged =
        JSON.stringify(this.establishment) !==
        JSON.stringify(this.prevEstablishment)

      if (!landingChanged && !establishmentChanged) {
        this.successToast(
          "Se han editado tus datos",
          "Ningun dato ha cambiado",
          "EditIcon"
        )

        return
      }

      const result = await this.$swal({
        title: "¿Estás seguro?",
        text: "Estás apunto de editar la información de el establecimiento",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, editar",
        cancelButtonText: "No, cancelar",
        customClass: {
          confirmButton: "btn btn-primary ml-1",
          cancelButton: "btn btn-outline-danger",
        },
        reverseButtons: true,
        buttonsStyling: false,
      })

      if (result.value) {
        try {
          this.$swal({
            title: "Guardando...",
            text: "Estamos guardando tus datos",
            didOpen: () => {
              this.$swal.showLoading()
            },
            allowOutsideClick: false,
            allowEscapeKey: false,
          })

          if (landingChanged) await this.updateLandingPage()
          if (establishmentChanged) await this.updateEstablishment()

          this.successToast(
            "Se han editado tus datos",
            "Los datos han sido editados con éxito",
            "EditIcon"
          )
        } catch (e) {
          if (e.response && e.response.data) {
            this.fetchErrorToast(e.response?.data, "Ocurrió un error")
          } else {
            this.errorToast("Por favor, intenta de nuevo", "Ocurrió un error")
          }

          this.establishment = { ...this.prevEstablishment }
          this.landingPage = { ...this.prevLandingPage }
        } finally {
          this.$swal.close()
        }
      }
    },

    async updateLandingPage() {
      const regex = /^https:\/\/mywalleat\./

      let banners = []

      for (let i = 0; i < 5; i++) {
        const newImage = this.landingPage.images[i]?.url

        if (newImage && !regex.test(newImage)) {
          banners.push(newImage)
        }
      }

      const {
        images,
        image_1,
        image_2,
        image_3,
        image_4,
        image_5,
        ...landingPage
      } = this.landingPage

      landingPage.banners = banners
      landingPage.images_to_remove = this.imagesToDestroy

      const res = await this.editLandingPage({
        establishment_id: this.$route.params.id,
        landing_page: landingPage,
      })

      this.landingPage = structuredClone(res)
      this.prevLandingPage = structuredClone(this.landingPage)
    },
    async updateEstablishment() {
      this.establishment.address_attributes.id = this.addressId
      await this.editEstablishment({
        id: this.$route.params.id,
        establishment: this.establishment,
      })

      this.establishment = { ...this.establishment }
      this.prevEstablishment = { ...this.establishment }
    },
  },
}
</script>

<style lang="scss" scoped>
.submit__buttons {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.add__image__container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100px;
  width: 100px;
  position: relative;
  @media (max-width: 768px) {
    height: 100px;
    width: 100px;
  }
}

.base-cropper__container {
  display: flex;
  gap: 0.5rem;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  @media (max-width: 768px) {
    justify-content: center;
  }
}

.remove__icon {
  cursor: pointer;
  position: absolute;
  right: 5%;
  top: 5%;
  color: red;
  z-index: 1;
}

.landing__form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
</style>
