<template>
  <div>
    <div class="d-flex align-items-center justify-content-center">
      <b-img
        v-if="cropped !== ''"
        class="cursor-pointer soft-border"
        rounded
        width="150"
        :src="cropped"
        @click="chooseFile()"
      />
      <b-img
        v-else
        key="img2"
        class="cursor-pointer soft-border"
        rounded
        width="100"
        src="/logo.png"
        @click="chooseFile()"
      />
    </div>
    <b-form-file
      :id="`fileInput-${index}`"
      type="file"
      class="d-none"
      accept="image/*"
      ref="fileInput"
      @change="croppie"
    />
    <b-modal
      :id="`croppieModal-${index}`"
      size="lg"
      cancel-variant="outline-secondary"
      cancel-title="Cancelar"
      @ok="handleEmit()"
    >
      <vue-croppie
        ref="cropRef"
        :enable-resize="false"
        :enable-orientation="false"
        :mouse-wheel-zoom="true"
        :boundary="{ width: 700, height: 300 }"
        :viewport="{ width: 700, height: 300, type: 'rectangle' }"
        @result="result"
        @update="crop"
      />
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      cropped: this.image,
    }
  },
  methods: {
    croppie(e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return

      const reader = new FileReader()
      reader.onload = (el) => {
        this.$refs.cropRef.bind({
          url: el.target.result,
        })
      }
      reader.readAsDataURL(files[0])

      this.$bvModal.show(`croppieModal-${this.index}`)
    },
    result(output) {
      this.cropped = output
    },
    crop() {
      const options = {
        format: "png",
        square: false,
        size: "original",
        quality: 1,
      }
      this.$refs.cropRef.result(options, (output) => {
        this.cropped = output
      })
    },
    handleEmit() {
      this.$emit("update:image", this.cropped)
    },
    chooseFile() {
      this.$refs.fileInput.value = null
      document.getElementById(`fileInput-${this.index}`).click()
    },
    resetCroppie() {
      this.cropped = null
      if (this.$refs.cropRef) {
        this.$refs.cropRef.bind({ url: "" })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
.soft-border {
  border: 4px solid rgba(122, 122, 122, 0.1);
}
</style>
