import { render, staticRenderFns } from "./EstablishmentEditTab.vue?vue&type=template&id=ac840aa4&scoped=true&"
import script from "./EstablishmentEditTab.vue?vue&type=script&lang=js&"
export * from "./EstablishmentEditTab.vue?vue&type=script&lang=js&"
import style0 from "./EstablishmentEditTab.vue?vue&type=style&index=0&id=ac840aa4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ac840aa4",
  null
  
)

export default component.exports